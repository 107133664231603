export const metaNames = {
  homepage: 'Homepage',
  about: 'About',
  ambassador: 'Ambassador',
  ambassadorFaq: 'Ambassador faq',
  contactUs: 'Contact us',
  styleQuiz: 'Style quiz',
  getTheLook: 'Get the look',
  requestTheLook: 'Request the look',
  curateTheLook: 'Curate the look',
};
