import React from 'react';
import { Rnd } from 'react-rnd';
import PropTypes from 'prop-types';
import SpriteIcon from 'components/ui/SpriteIcon';
import IconButton from 'components/ui/IconButton/IconButton';
import resizeImage from 'assets/img/resize.jpg';
import classes from './ImgDragModal.module.scss';

const ImgDragModal = ({ open, onClose, url }) => {
  const defaultWidth = 700;

  return (
    open && (
      <Rnd
        style={{ zIndex: 9999, position: 'fixed' }}
        default={{
          x: 20,
          y: -120,
          width: defaultWidth,
        }}
        minWidth="200px"
        minHeight="200px"
        lockAspectRatio
      >
        <div className={classes.root}>
          <IconButton
            aria-label="close"
            onClick={onClose}
            className={classes.closeBtn}
          >
            <SpriteIcon name="cross" size="sm" />
          </IconButton>
          <img src={url} alt="" className={classes.img} />
          <img src={resizeImage} alt="resize" className={classes.resizeIcon} />
        </div>
      </Rnd>
    )
  );
};

ImgDragModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  url: PropTypes.string,
};

ImgDragModal.defaultProps = {
  url: undefined,
};

export default ImgDragModal;
