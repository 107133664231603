import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Button from 'components/ui/Button/Button';
import nomineesService from 'modules/dashboard/nominees/nomineesService';
import NomineeThumbnail from 'components/userThumbnails/NomineeThumbnail/NomineeThumbnail';
import SendMessageModal from 'components/modals/SendMessageModal/SendMessageModal';
import { messageFieldKeys } from 'components/modals/SendMessageModal/constants';
import { routesByName } from 'constants/routes';
import errorToastr from 'libs/toastr/errorToastr';
import useCancelToken from 'hooks/useCancelToken';
import classes from 'modules/dashboard/nominees/Nominees.module.scss';

const NomineesContainer = () => {
  const currentUser = useSelector((state) => state.auth.user);

  const [nomineesList, setNomineesList] = useState([]);
  const [messageModalOpen, setMessageModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [createCancelToken, isCanCelled] = useCancelToken();

  const registeredNominees = useMemo(
    () => nomineesList.filter(({ registered }) => Boolean(registered)),
    [nomineesList]
  );

  const notRegisteredNominees = useMemo(
    () => nomineesList.filter(({ registered }) => !registered),
    [nomineesList]
  );

  const initialFormValues = useMemo(
    () => ({
      [messageFieldKeys.name]: selectedUser
        ? `${selectedUser.firstName} ${selectedUser.lastName}`
        : '',
      [messageFieldKeys.userId]: selectedUser?.id ?? null,
    }),
    [selectedUser]
  );

  useEffect(() => {
    (async () => {
      try {
        const cancelToken = createCancelToken();
        const { result: list } = await nomineesService.getNominees({
          cancelToken,
        });

        const listWithoutCurrentUser = list.filter(
          ({ id }) => id !== currentUser.id
        );
        setNomineesList(listWithoutCurrentUser);
      } catch (e) {
        if (!isCanCelled(e)) {
          errorToastr('Error', e.generalError);
        }
      }
    })();
  }, [currentUser, isCanCelled, createCancelToken]);

  const handleOpenMessageModal = useCallback(
    (nomineeId) => {
      const user = nomineesList.find(({ id }) => id === nomineeId);
      setSelectedUser(user);
      setMessageModalOpen(true);
    },
    [nomineesList]
  );

  const handleCloseMessageModal = useCallback(() => {
    setMessageModalOpen(false);
  }, []);

  return (
    <>
      <h1 className={classes.title}>My Nominated Ambassador Team</h1>
      <div className="d-flex justify-content-end">
        <Link to={routesByName.nominateAmbassador}>
          <Button inline className="mb-3">
            Nominate More Friends to Join
          </Button>
        </Link>
      </div>
      <div className={classes.nomineesContainer}>
        <h2 className="text-lg font-semi-bold mb-3">Accepted Nominees</h2>
        <div className="row mb-5">
          {registeredNominees.length ? (
            registeredNominees.map(
              ({
                id,
                firstName: first,
                lastName: last,
                displayName,
                avatarUrl,
                registered,
                nominated,
              }) => (
                <div className="col-4 mb-2" key={id}>
                  <NomineeThumbnail
                    id={id}
                    firstName={first}
                    lastName={last}
                    displayName={displayName}
                    avatarUrl={avatarUrl}
                    registered={registered}
                    nominated={nominated}
                    onSendMessage={handleOpenMessageModal}
                  />
                </div>
              )
            )
          ) : (
            <div className="pl-1">No accepted nominees</div>
          )}
        </div>
        <h2 className="text-lg font-semi-bold mb-3">Pending Nominees</h2>
        <div className="row mb-5">
          {notRegisteredNominees.length ? (
            notRegisteredNominees.map(
              ({
                id,
                firstName: first,
                lastName: last,
                displayName,
                avatarUrl,
                registered,
                nominated,
              }) => (
                <div className="col-4 mb-2" key={id}>
                  <NomineeThumbnail
                    id={id}
                    firstName={first}
                    lastName={last}
                    displayName={displayName}
                    avatarUrl={avatarUrl}
                    registered={registered}
                    nominated={nominated}
                  />
                </div>
              )
            )
          ) : (
            <div className="pl-1">No pending nominees</div>
          )}
        </div>
      </div>
      <SendMessageModal
        open={messageModalOpen}
        onClose={handleCloseMessageModal}
        initialValues={initialFormValues}
      />
    </>
  );
};

export default NomineesContainer;
