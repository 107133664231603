import React from 'react';
import PropTypes from 'prop-types';
import { detailsPreviewProps } from 'modules/requestTheLook/components/DetailsPreview/propTypes';
import { requestTypes } from 'modules/requestTheLook/constants';
import { imageTypeKeys } from 'constants/inspirationImageSearchParams';
import { Link } from 'react-router-dom';
import SpriteIcon from 'components/ui/SpriteIcon';
import IconButton from 'components/ui/IconButton/IconButton';
import clsx from 'clsx';
import classes from './DetailsPreview.module.scss';
import BasicAvatar from '../../../../components/ui/BasicAvatar';

const DetailsPreview = ({
  title,
  requestType,
  priorityItems,
  styles,
  children,
  roomType,
  prevStepRoute,
  itemType,
  wider,
  message,
  country,
  requesterFirstName,
  requesterLastName,
  requesterDisplayName,
  requesterImage,
  requestColor,
  requestStyle,
  colorGroups,
  requestStyles,
}) => (
  <div className={clsx(classes.root, { [classes.wider]: wider })}>
    <h2 className={`${classes.title} text-ellipsis`}>{title}</h2>
    <ul className={classes.list}>
      {requesterFirstName && (
        <div className="d-flex align-items-center mb-3">
          <BasicAvatar
            firstName={requesterFirstName}
            lastName={requesterLastName}
            displayName={requesterDisplayName}
            src={requesterImage}
          />
          <li className={clsx('mb-0 mx-2 text-sm')}>
            <b>Requester Name: </b>{' '}
            {requesterDisplayName ??
              `${requesterFirstName} ${requesterLastName}`}
          </li>
        </div>
      )}
      <li className={classes.listItem}>
        <b>Request Type:</b> {requestTypes[requestType]}
      </li>
      {requestType === imageTypeKeys.room ||
        (requestType === imageTypeKeys.makeOver && roomType && (
          <li className={classes.listItem}>
            <b>Room Type:</b> {roomType}
          </li>
        ))}
      {requestType === imageTypeKeys.makeOver && requestColor.length && (
        <li className={classes.listItem}>
          <b>Request color:</b>
          <ul
            className={
              requestType === imageTypeKeys.room ? 'list-dotted' : classes.list
            }
          >
            {requestColor.map((color) => {
              return (
                <li key={color} className="text-capitalize">
                  {colorGroups[color].name}
                </li>
              );
            })}
          </ul>
        </li>
      )}
      {requestType === imageTypeKeys.makeOver && requestStyle.length && (
        <li className={classes.listItem}>
          <b>Request style:</b>
          <ul
            className={
              requestType === imageTypeKeys.room ? 'list-dotted' : classes.list
            }
          >
            {requestStyle.map((style) => {
              return <li key={style}>{requestStyles[style].name}</li>;
            })}
          </ul>
        </li>
      )}
      {styles.length > 0 && (
        <li className={classes.listItem}>
          <b className="text-capitalize">{itemType} Style:</b>{' '}
          {styles.join(', ')}
        </li>
      )}
      {priorityItems.length > 0 && (
        <li className={classes.listItem}>
          <b>
            {requestType === imageTypeKeys.room
              ? 'Priority Items:'
              : 'Item Type:'}
          </b>
          <ul
            className={
              requestType === imageTypeKeys.room ? 'list-dotted' : classes.list
            }
          >
            {priorityItems.map(({ label, value }) => (
              <li key={value}>{label}</li>
            ))}
          </ul>
        </li>
      )}
      {country && (
        <li className={classes.listItem}>
          <b>Requester Country: </b> {country}
        </li>
      )}

      {message && (
        <li className={classes.listItem}>
          <b>Message: </b> <span style={{ lineHeight: 1.7 }}>{message}</span>
        </li>
      )}
    </ul>
    {prevStepRoute && (
      <Link className={classes.editRequest} to={prevStepRoute}>
        <IconButton variant="inverted-grey" color="secondary" size="sm">
          <SpriteIcon name="edit" size="sm" />
        </IconButton>
      </Link>
    )}
    {children}
  </div>
);

DetailsPreview.propTypes = {
  ...detailsPreviewProps,
  children: PropTypes.node,
  roomType: PropTypes.string,
  prevStepRoute: PropTypes.string,
  wider: PropTypes.bool,
  message: PropTypes.string,
  country: PropTypes.string,
  requesterFirstName: PropTypes.string,
  requesterLastName: PropTypes.string,
  requesterImage: PropTypes.string,
  requesterDisplayName: PropTypes.string,
};

DetailsPreview.defaultProps = {
  children: null,
  roomType: null,
  prevStepRoute: null,
  wider: false,
  message: null,
  country: null,
  requesterFirstName: '',
  requesterLastName: '',
  requesterImage: null,
  requesterDisplayName: null,
};

export default DetailsPreview;
