import React from 'react';
import { Link } from 'react-router-dom';
import { routesByName } from 'constants/routes';
import SpriteIcon from 'components/ui/SpriteIcon';
import affiliateProgram from 'assets/img/ambassador-page/affiliate-program.jpg';
import classes from './Banner.module.scss';

const Banner = () => (
  <div className={classes.root}>
    <div className={classes.shadowShape} />
    <div className={classes.shape}>
      <h2 className={classes.title}>Love Decorating?</h2>
      <p className={classes.description}>
        Learn more about becoming an Ambassador <br /> and starting your <br />{' '}
        own home furnishing side <br /> gig on Pin the Look.
      </p>
      <Link to={routesByName.ambassador.index} className={classes.link}>
        Learn More
        <SpriteIcon name="play-btn-icon" size="md" className="ml-1" />
      </Link>
    </div>
    <img src={affiliateProgram} alt="Affiliate program" />
  </div>
);

export default Banner;
