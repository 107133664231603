import React, { useMemo } from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

const ShowcaseMeta = ({ showcase }) => {
  const rout = useMemo(
    () => `${window.location.origin}/showcase/${showcase.slug}`,
    [showcase.slug]
  );
  return (
    <Helmet>
      <link rel="canonical" href={rout} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={showcase.metaTitle} />
      <meta property="og:description" content={showcase.metaDescription} />
      <meta name="description" content={showcase.metaDescription} />
      <meta property="og:image" content={showcase.image} />
      <meta property="og:url" content={window.location.href} />

      {/* Schema.org Structured Data */}
      <script type="application/ld+json">
        {`
          {
            "@context": "https://schema.org/",
            "@type": "WebPage",
            "name": "${showcase.metaTitle}",
            "description": "${showcase.metaDescription}",
            "image": "${showcase.image}",
            "url": "${window.location.href}"
          }
        `}
      </script>
    </Helmet>
  );
};

ShowcaseMeta.propTypes = {
  showcase: PropTypes.shape({
    metaTitle: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    metaDescription: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
  }).isRequired,
};

export default ShowcaseMeta;
