import React, { useCallback, useState } from 'react';
import clsx from 'clsx';
import classes from 'components/PreferenceQuiz/steps/steps.module.scss';

import PropTypes from 'prop-types';
import BasicModal from 'components/modals/BasicModal/BasicModal';
import VibesCard from 'components/PreferenceQuiz/VibesCard/VibesCard';
import Prompt from './Prompt/Prompt';
import useMediaQuery from '../../../../hooks/useMediaQuery';
import { maxWidthMd } from '../../../../constants/mediaQueries';

const VibesFilter = ({
  options,
  currentValue,
  onChange,
  isPromptShow,
  promptText,
}) => {
  const matchesMediaQuery = useMediaQuery(maxWidthMd);

  const [imgModalOpen, setImgModalOpen] = useState(false);
  const [imgPreviewUrl, setImgPreviewUrl] = useState(null);

  const handleOpenImgModal = useCallback((imgUrl) => {
    setImgPreviewUrl(imgUrl);
    setImgModalOpen(true);
  }, []);

  const handleImgModalClose = useCallback(() => {
    setImgModalOpen(false);
  }, []);
  return (
    <>
      <div className={clsx('row', classes.stylesContainer)}>
        {options.map((option) =>
          matchesMediaQuery ? (
            <div className="w-100 mb-3" key={option.id}>
              <VibesCard
                option={option}
                selected={currentValue.includes(option.id)}
                onChange={onChange}
                onOpenPreviewModal={handleOpenImgModal}
              />
            </div>
          ) : (
            <div className="col-6 mb-3" key={option.id}>
              <VibesCard
                option={option}
                selected={currentValue.includes(option.id)}
                onChange={onChange}
                onOpenPreviewModal={handleOpenImgModal}
              />
            </div>
          )
        )}
      </div>
      <Prompt open={isPromptShow} text={promptText} />
      <BasicModal
        onClose={handleImgModalClose}
        open={imgModalOpen}
        classes={{ root: classes.imgPreviewModal }}
        maxWidth="md"
      >
        <img src={imgPreviewUrl} alt="Preview" />
      </BasicModal>
    </>
  );
};

export default VibesFilter;

VibesFilter.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  currentValue: PropTypes.arrayOf(PropTypes.number).isRequired,
  onChange: PropTypes.func.isRequired,
  isPromptShow: PropTypes.bool,
  promptText: PropTypes.string,
};

VibesFilter.defaultProps = {
  isPromptShow: false,
  promptText: '',
};
