import React, { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { SpeedDialAction } from '@material-ui/lab';
import { bannerTypeKeys } from 'modules/dashboard/showcase/constants';
import SpriteIcon from 'components/ui/SpriteIcon';
import IconButton from 'components/ui/IconButton/IconButton';
import BasicAvatar from 'components/ui/BasicAvatar';
import { showcaseShape } from 'modules/showcase/propTypes';
import { userShape } from 'modules/currentUser/propTypes';
import useCurrentUserAvatarUrl from 'hooks/useCurrentUserAvatarUrl';
import buildResourceUrl from 'utils/buildResourceUrl';
import findObjectById from 'utils/findObjectById';
import classes from 'modules/dashboard/showcase/Showcase.module.scss';
import useUserStatus from 'hooks/useUserStatus';
import TextInput from 'components/inputs/TextInput/TextInput';
import CustomSpeedDial from 'components/ui/SpeedDial/CustomSpeedDial';
import { tooltipShareText } from 'components/LookBoardShare/constants';
import SetupShowcaseHint from './components/SetupShowcaseHint';
import InfoIconPopover from '../../../components/ui/InfoIconPopover/InfoIconPopover';
import showcaseProfile from '../../../assets/img/dashboard-page/showcase/pin-card.jpg';
import AmbassadorCard from '../../../components/userThumbnails/AmbassadorCard/AmbassadorCard';
import appConfig from '../../../constants/appConfig';

const ShowcaseHeader = ({
  showcase,
  handleOpenBannerModal,
  handleOpenAddPageDataModal,
  user,
  styles,
  handleOpenStyleStoryModal,
  handleOpenStylesModal,
  bannerUrlPlaceholders,
  handleLinkInput,
  youtubeLink,
  handleUpdateLink,
}) => {
  const avatarUrl = useCurrentUserAvatarUrl();

  const badge = useSelector((state) => state.auth.user?.badge);
  const status = useUserStatus(badge);
  const [showLinkError, setShowLinkError] = useState(false);
  const currentUser = useSelector((state) => state.auth.user);

  const bannerUrl = useMemo(() => {
    if (!showcase) return null;
    const {
      banner: { media, type: bannerType },
    } = showcase;
    return bannerType === bannerTypeKeys.image && media
      ? buildResourceUrl(bannerUrlPlaceholders.large, media.userId, media.hash)
      : null;
  }, [bannerUrlPlaceholders, showcase]);

  const handleLinkChange = (value) => {
    const regExp = /(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?feature=player_embedded&v=))([^&?]+)?/;
    const match = value.match(regExp);
    setShowLinkError(!(match || value === ''));

    handleLinkInput(value);
  };

  const currentAmbassadorStyles = useMemo(
    () =>
      showcase?.styles.map((styleId) => findObjectById(styleId, styles)?.name),
    [showcase, styles]
  );

  const handleDownloadImage = useCallback(() => {
    const link = document.createElement('a');
    link.href = showcaseProfile;
    link.download = 'showcaseProfile.png';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }, []);

  const handleDownloadImageByLink = useCallback(() => {
    const link = document.createElement('a');
    link.href = `${appConfig.apiUrl}/v1/showcase/download/${showcase.userId}`;
    link.download = `${showcase.pageName}.png`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }, [showcase.pageName, showcase.userId]);

  return (
    <>
      <div
        className={classes.bannerContainer}
        style={{
          background:
            showcase.banner.type === bannerTypeKeys.color
              ? showcase.banner.color
              : 'none',
        }}
      >
        <div className={classes.mask} />
        {showcase.banner.type === bannerTypeKeys.image && (
          <img src={bannerUrl} alt="" />
        )}
        <button
          type="button"
          className={classes.editBannerBtn}
          onClick={handleOpenBannerModal}
        >
          <div className={classes.editIcon}>
            <SpriteIcon name="edit" size="sm" />
          </div>
          <span className="text-sm">Edit Banner Design</span>
        </button>
        <div className={classes.step1}>
          <SetupShowcaseHint text="STEP 1: Select or Upload a New Banner Image." />
        </div>
        <div className={classes.bannerTitleBlock}>
          <h2 className={`${classes.mainTitle} mb-1`}>{showcase.pageName}</h2>
          <div className="d-flex justify-content-center align-items-center mb-1">
            <IconButton
              variant="inverted-white"
              size="xs"
              className="mr-1"
              onClick={handleOpenAddPageDataModal}
            >
              <SpriteIcon name="edit" size="xs" />
            </IconButton>
            <span className="text-sm">Edit Text</span>
          </div>
          {showcase.isSubtitleActive && (
            <p className={classes.subtitle}>{showcase.subtitle}</p>
          )}
        </div>
      </div>
      <div className={classes.userCard}>
        {status && (
          <div className={classes.status}>
            <img src={status.icon} alt="status" />
          </div>
        )}
        <div className="d-flex justify-content-between position-relative">
          <div>
            <div className=" ml-3 d-flex align-items-center">
              <BasicAvatar
                firstName={user.firstName}
                lastName={user.lastName}
                displayName={user.displayName}
                src={avatarUrl}
                className={classes.avatar}
              />
              <div className={classes.nameContainer}>
                <p className={`${classes.fullName} text-ellipsis`}>
                  {user.displayName ?? `${user.firstName} ${user.lastName}`}
                </p>
              </div>
            </div>
          </div>
          <div className="d-flex flex-column justify-content-between align-items-end position-relative">
            {
              //   personalShopper && (
              //   <div className={classes.personalShopperThumb}>
              //     <div className={classes.premiumIcon}>
              //       <SpriteIcon name="premium-quality" size="sm" />
              //     </div>
              //     <span className={classes.text}>Personal shopper</span>
              //   </div>
              // )
            }
            <div className="position-absolute">
              <CustomSpeedDial
                mainIcon={
                  <IconButton
                    variant="inverted-grey"
                    color="grey"
                    size="sm"
                    className="mt-auto"
                  >
                    <SpriteIcon name="share" size="sm" />
                  </IconButton>
                }
              >
                <SpeedDialAction
                  key="showcaseCardShare"
                  icon={
                    <InfoIconPopover
                      iconProps={{
                        className: '',
                        size: 'md',
                        color: 'primary',
                      }}
                      size="md"
                      vertAlignment="top"
                      customIcon="pin-icon"
                    >
                      <img
                        src={showcaseProfile}
                        alt="showcase"
                        style={{ width: '400px' }}
                      />
                      <div
                        className={classes.showcasePopOverText}
                        onClick={handleDownloadImage}
                      >
                        <SpriteIcon
                          name="download-arrow"
                          width="25"
                          height="25"
                          className={classes.showcasePopOverIcon}
                        />
                        Download Pin the Look Card
                      </div>
                    </InfoIconPopover>
                  }
                  tooltipTitle={tooltipShareText.pinIcon}
                />
                <SpeedDialAction
                  key="ambassadorCardShare"
                  icon={
                    <InfoIconPopover
                      iconProps={{
                        className: '',
                        size: 'md',
                        color: 'primary',
                      }}
                      size="md"
                      vertAlignment="top"
                      customIcon="user"
                    >
                      <AmbassadorCard
                        {...showcase}
                        {...currentUser}
                        bannerUrl={bannerUrl}
                        avatarUrl={avatarUrl}
                        lookBoardsCount={showcase?.user.lookBoards}
                        title={showcase?.pageName}
                        styles={currentAmbassadorStyles}
                        disableHover
                        isShare
                      />
                      <div
                        className={classes.showcasePopOverText}
                        onClick={handleDownloadImageByLink}
                      >
                        <SpriteIcon
                          name="download-arrow"
                          width="25"
                          height="25"
                          className={classes.showcasePopOverIcon}
                        />
                        Download Showcase Card
                      </div>
                    </InfoIconPopover>
                  }
                  tooltipTitle={tooltipShareText.profileIcon}
                />
              </CustomSpeedDial>
            </div>
          </div>
        </div>
      </div>
      <div className="background-white px-5 py-3 mb-5 position-relative">
        <div className={classes.step3}>
          <SetupShowcaseHint text="STEP 3: Write about what inspired your style theme." />
        </div>
        <div className="d-flex mb-3">
          <div style={{ minWidth: 250 }}>
            <p className={`${classes.sectionTitle} mb-1`}>My style story</p>
            <div className="d-flex align-items-center">
              <p className="font-italic primary-color mr-1">
                150 words or less
              </p>
              <IconButton
                variant="inverted-grey"
                size="xs"
                className="mr-1"
                onClick={handleOpenStyleStoryModal}
              >
                <SpriteIcon name="edit" size="xs" />
              </IconButton>
            </div>
          </div>
          <p className="text-gray" style={{ lineHeight: 1.5 }}>
            {showcase.description}
          </p>
        </div>
        <div className="d-flex">
          <p className="text-lg font-semi-bold" style={{ minWidth: 250 }}>
            Favorite Styles:
          </p>
          <div className="d-flex flex-wrap align-items-center">
            {showcase.styles.length > 0 &&
              showcase.styles.map((styleId) => (
                <div key={styleId} className={classes.styleChip}>
                  {findObjectById(styleId, styles)?.name}
                </div>
              ))}
            <IconButton
              variant="inverted-grey"
              size="sm"
              className="mb-1"
              onClick={handleOpenStylesModal}
            >
              <SpriteIcon name="edit" size="sm" />
            </IconButton>
          </div>
        </div>

        <div className="d-flex position-relative align-items-center">
          <p className="text-lg font-semi-bold" style={{ minWidth: 250 }}>
            Add Video Intro:
          </p>
          <div className="d-flex flex-wrap align-items-start flex-column">
            <div className="d-flex align-items-center">
              <TextInput
                label="YouTube link"
                onInput={({ target: { value } }) => handleLinkChange(value)}
                className="mr-1"
                value={youtubeLink}
                InputProps={{
                  style: { minWidth: 250 },
                }}
              />
              <button
                type="button"
                className={classes.youtubeBtn}
                onClick={handleUpdateLink}
                disabled={showLinkError}
              >
                <div className={classes.triangle} />
              </button>
            </div>
            {showLinkError && (
              <p className={classes.linkError}>
                Please enter correct youtube link.
              </p>
            )}
          </div>
          <div className={classes.step4}>
            <SetupShowcaseHint text="STEP 4 (Optional): Add a Video Intro." />
          </div>
        </div>
      </div>
    </>
  );
};

ShowcaseHeader.propTypes = {
  showcase: showcaseShape.isRequired,
  handleOpenBannerModal: PropTypes.func.isRequired,
  handleOpenAddPageDataModal: PropTypes.func.isRequired,
  user: userShape.isRequired,
  styles: PropTypes.shape({}).isRequired,
  handleOpenStyleStoryModal: PropTypes.func.isRequired,
  handleOpenStylesModal: PropTypes.func.isRequired,
  bannerUrlPlaceholders: PropTypes.shape({
    large: PropTypes.string.isRequired,
  }).isRequired,
  handleLinkInput: PropTypes.func.isRequired,
  youtubeLink: PropTypes.string,
  handleUpdateLink: PropTypes.func.isRequired,
};

ShowcaseHeader.defaultProps = {
  youtubeLink: '',
};
export default ShowcaseHeader;
