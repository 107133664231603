import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import BasicAvatar from 'components/ui/BasicAvatar';
import IconButton from 'components/ui/IconButton/IconButton';
import SpriteIcon from 'components/ui/SpriteIcon';
import classes from './NomineeThumbnail.module.scss';

const NomineeThumbnail = ({
  className,
  id,
  firstName,
  lastName,
  displayName,
  avatarUrl,
  registered,
  nominated,
  onSendMessage,
}) => {
  const nominationDate = useMemo(() => moment(nominated).format('DD.MM.YYYY'), [
    nominated,
  ]);

  const registrationDate = useMemo(
    () => moment(registered).format('DD.MM.YYYY'),
    [registered]
  );

  const handleSendMessage = useCallback(() => {
    onSendMessage(id);
  }, [onSendMessage, id]);

  return (
    <div className={clsx(classes.root, className)}>
      <div className="flex-vertical-center">
        <BasicAvatar
          className={classes.avatar}
          firstName={firstName}
          lastName={lastName}
          displayName={displayName}
          src={avatarUrl}
        />
      </div>
      <div className={classes.userInfo}>
        <p className={`${classes.title} text-ellipsis`}>
          {`${firstName} ${lastName}`}
        </p>
        <p className="mb-1 text-xs">
          <span className="font-semi-bold">Nomination Date:</span>{' '}
          <span>{nominationDate}</span>
        </p>
        {registered && (
          <p className="text-xs">
            <span className="font-semi-bold">Date Registered:</span>{' '}
            <span>{registrationDate}</span>
          </p>
        )}
      </div>
      {registered && (
        <div className={classes.emailIcon}>
          <IconButton
            size="sm"
            variant="inverted-grey"
            onClick={handleSendMessage}
          >
            <SpriteIcon name="envelope" size="sm" />
          </IconButton>
        </div>
      )}
    </div>
  );
};

NomineeThumbnail.propTypes = {
  id: PropTypes.number.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  displayName: PropTypes.string.isRequired,
  avatarUrl: PropTypes.string,
  nominated: PropTypes.number.isRequired,
  className: PropTypes.string,
  registered: PropTypes.number,
  onSendMessage: PropTypes.func,
};

NomineeThumbnail.defaultProps = {
  className: '',
  registered: undefined,
  avatarUrl: null,
  onSendMessage: () => {},
};

export default NomineeThumbnail;
