import React, { useState, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import SpriteIcon from 'components/ui/SpriteIcon';
import Button from 'components/ui/Button/Button';
import HowItWorksVideoModal from 'components/modals/HowItWorksVideoModal';
import BannerButton from 'modules/home/components/Banner/BannerButton/BannerButton';
import RequestModal from 'modules/home/components/Banner/RequestModal/RequestModal';
import DiscoverModal from 'modules/home/components/Banner/DiscoverModal';
import { toggleHowItWorksVideoModalAction } from 'modules/layout/store/actions';
import buildResourceUrl from 'utils/buildResourceUrl';
import bannerBg from 'assets/img/home-page/main-banner.jpg';
import posterImg from 'assets/video/homepage-preview.png';
import { routesByName } from 'constants/routes';
import classes from './Banner.module.scss';

const Banner = () => {
  const dispatch = useDispatch();
  const videoUrlPlaceholder = useSelector(
    (state) => state.app.config.endpoints.media.videos.standart
  );

  const [requestModalOpen, setRequestModalOpen] = useState(false);
  const [discoverModalOpen, setDiscoverModalOpen] = useState(false);

  const history = useHistory();

  const handleOpenRequestModal = useCallback(() => {
    history.push(routesByName.requestTheLook.index);
  }, [history]);

  const handleCloseRequestModal = useCallback(() => {
    setRequestModalOpen(false);
  }, []);

  const handleOpenDiscoverModal = useCallback(() => {
    // setDiscoverModalOpen(true);
    history.push(routesByName.styleQuiz);
  }, [history]);

  const handleCloseDiscoverModal = useCallback(() => {
    setDiscoverModalOpen(false);
  }, []);

  const handleHowItWorksModalOpen = useCallback(() => {
    dispatch(toggleHowItWorksVideoModalAction(true));
  }, [dispatch]);

  const handleOpenBecomePage = useCallback(() => {
    history.push(routesByName.ambassador.index);
  }, [history]);

  const handleAmbassadorPage = useCallback(() => {
    history.push(routesByName.ambassador.index);
  }, [history]);

  const videoHomepageLink = useMemo(() => {
    return buildResourceUrl(videoUrlPlaceholder, null, null, 'homepage');
  }, [videoUrlPlaceholder]);

  return (
    <>
      <section className={classes.root}>
        <Button
          inline
          className={classes.fixedBtn}
          onClick={handleHowItWorksModalOpen}
        >
          How it Works
          <SpriteIcon name="play-icon" size="md" className="ml-2" />
        </Button>
        <img src={bannerBg} alt="banner" />
        <div className={classes.box}>
          <h1 className={classes.titleLg}>
            Getting the Look you Love for your Home
          </h1>
          <h2 className={classes.titleMd}>Just Got Easy</h2>
          <BannerButton
            onClick={handleOpenDiscoverModal}
            title="Take the Style Quiz"
            subTitle="Get your Style Profile & Curated Looks"
          />
          <BannerButton
            onClick={handleOpenRequestModal}
            title="Request the Look"
            subTitle="Get Decor Ideas from Skilled Ambassadors"
          />
          <BannerButton
            onClick={handleOpenBecomePage}
            title="Become an Ambassador"
            subTitle="Love Decorating? Join Us!"
          />
          <div
            className="text-lg text-center mt-5  cursor-pointer"
            onClick={handleAmbassadorPage}
          >
            Let’s Decorate the World{' '}
            <span className="primary-color">Together</span>
          </div>
        </div>
      </section>
      <RequestModal open={requestModalOpen} onClose={handleCloseRequestModal} />
      <DiscoverModal
        open={discoverModalOpen}
        onClose={handleCloseDiscoverModal}
      />
      <HowItWorksVideoModal video={videoHomepageLink} posterImg={posterImg} />
    </>
  );
};

export default Banner;
