import React from 'react';
import { Link, Route, Switch } from 'react-router-dom';
import { routesByName } from 'constants/routes';
import AmbassadorRoute from 'components/AmbassadorRoute';
import CreateRequestContainer from 'modules/requestTheLook/createRequest/CreateRequestContainer';
import RequestDetailsContainer from 'modules/requestTheLook/requestDetails/RequestDetailsContainer';
import SubmitRequestContainer from 'modules/requestTheLook/submitRequest/SubmitRequestContainer';
import RequestSuccessContainer from 'modules/requestTheLook/requestSuccess/RequestSuccessContainer';
import RecommendationsContainer from 'modules/requestTheLook/recommendations/RecommendationsContainer';
import AmbassadorMatchContainer from 'modules/requestTheLook/ambassadorMatch/AmbassadorMatchContainer';
import Page404 from 'modules/pages/Page404';
import useMediaQuery from 'hooks/useMediaQuery';
import { maxWidthMd } from 'constants/mediaQueries';
import requestSectionImg from 'assets/img/home-page/how-it-works/request.png';
import clsx from 'clsx';
import classes from 'components/StubMobilePage/StubMobilePage.module.scss';
import Button from 'components/ui/Button/Button';
import { useRegisterEffect } from 'hooks/useRegisterEffect';
import { useSelector } from 'react-redux';
import { metaNames } from '../../components/MetaTags/config';
import MetaTags from '../../components/MetaTags';

const RequestTheLookContainer = () => {
  const matchesMediaQuery = useMediaQuery(maxWidthMd);
  const metaTags = useSelector((state) => state.app.enums.metaTags);

  useRegisterEffect();
  return matchesMediaQuery ? (
    <>
      {metaTags && metaTags[metaNames.requestTheLook] && (
        <MetaTags metaData={metaTags[metaNames.requestTheLook]} />
      )}
      <div className="d-flex flex-fill flex-column h-100 justify-content-center">
        <div className="py-3 px-3">
          <div className="text-center mb-2">
            <h1 className={classes.title}>Request the Look</h1>
            <p className={classes.subTitle}>
              of your Favorite Room & Product Images
            </p>
          </div>
          <div
            className={classes.imageWrapper}
            style={{
              width: matchesMediaQuery ? '100%' : 205,
              height: matchesMediaQuery ? '100%' : 205,
            }}
          >
            <img
              className={clsx({
                'mb-3': !matchesMediaQuery,
              })}
              src={requestSectionImg}
              alt=""
            />
          </div>
        </div>
        <div className={classes.useDesktop}>— Use PC to Request the Look —</div>
        <div className="px-3">
          <Button className="mb" component={Link} to={routesByName.styleQuiz}>
            Take the Style Quiz
          </Button>
        </div>
      </div>
    </>
  ) : (
    <>
      {metaTags && metaTags[metaNames.requestTheLook] && (
        <MetaTags metaData={metaTags[metaNames.requestTheLook]} />
      )}
      <Switch>
        <Route exact path={routesByName.requestTheLook.index}>
          <CreateRequestContainer />
        </Route>
        <Route exact path={routesByName.requestTheLook.details}>
          <RequestDetailsContainer />
        </Route>
        <Route exact path={routesByName.requestTheLook.submit}>
          <SubmitRequestContainer />
        </Route>
        <Route exact path={routesByName.requestTheLook.success}>
          <RequestSuccessContainer />
        </Route>
        <AmbassadorRoute
          exact
          path={routesByName.requestTheLook.ambassadorMatch()}
        >
          <AmbassadorMatchContainer />
        </AmbassadorRoute>
        <Route exact path={routesByName.requestTheLook.recommendations()}>
          <RecommendationsContainer />
        </Route>
        <Route exact path="*" component={Page404} />
      </Switch>
    </>
  );
};

export default RequestTheLookContainer;
