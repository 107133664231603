import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import CustomInfiniteScroll from 'components/CustomInfiniteScroll';
import useCustomEventListener from 'hooks/useCustomEventListener';
import {
  SET_INITIAL_SCROLLER_HEIGHT,
  UPDATE_SCROLLER_HEIGHT,
} from 'constants/customEventNames';
import SpriteIcon from 'components/ui/SpriteIcon';
import buildResourceUrl from 'utils/buildResourceUrl';
import {
  getColors,
  getImageType,
  getInspirationImgUrl,
  getStyleNames,
  getUserAvatarThumbUrl,
} from 'modules/app/store/selectors';
import {
  imageSourceKeys,
  imageTypeKeys,
} from 'constants/inspirationImageSearchParams';
import {
  toggleTermsModalAction,
  toggleUnderConstructionModalAction,
} from 'modules/layout/store/actions';
import {
  updateIILibraryAction,
  updateIIStatusLikeAction,
} from 'modules/inspirationImage/store/actions';
import {
  setClaimImageIdAction,
  toggleClaimImageModalAction,
} from 'modules/report/store/actions';
import Button from 'components/ui/Button/Button';
import CustomScrollBar from 'components/ui/CustomScrollbar/CustomScrollBar';
import inspirationImageService from 'modules/inspirationImage/inspirationImageService';
import errorToastr from 'libs/toastr/errorToastr';
import SimilarImageItem from 'modules/curateTheLook/createLookBoard/components/SimilarImageItem/SimilarImageItem';
import currentUserService from 'modules/currentUser/currentUserService';
import MoreOptionsPopover from 'modules/curateTheLook/createLookBoard/components/MoreOptionsPopover/MoreOptionsPopover';
import SharePopover from 'components/modals/SharePopover/SharePopover';
import moreOptionsClasses from 'modules/curateTheLook/createLookBoard/components/MoreOptionsPopover/MoreOptionsPopover.module.scss';
import useFollowings from 'hooks/useFollowings';
import ConfirmModal from 'components/modals/ConfirmModal';
import useCancelToken from 'hooks/useCancelToken';
import { useHistory, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import { routesByName } from 'constants/routes';
import useMediaQuery from 'hooks/useMediaQuery';
import { maxWidthMd } from 'constants/mediaQueries';
import IconButton from 'components/ui/IconButton/IconButton';
import BasicAvatar from 'components/ui/BasicAvatar';
import ImageLink from 'components/imageThumbnails/ImageLink/ImageLink';
import classes from './SelectedImageBlock.module.scss';
import {
  resetCanvasAction,
  selectImageAction as selectCTLImageAction,
} from '../../../store/actions';
import {
  approvalStatusKeys,
  publishStatusKeys,
} from '../../../../inspirationImage/constants';

// TODO: Get limit value from application config
const limit = 10;
const unauthorizedLimit = 100;

const SelectedImageBlock = ({
  inspirationImageId,
  requestId,
  searchParams,
  onBack,
  onOpenPreview,
  onSelectImage,
  userAvatarThumbUrl,
  inspirationImageUrl,
  updateLikeStatus,
  toggleClaimImageModal,
  setClaimImageId,
  toggleTermsModal,
  updateIILibrary,
  authenticated,
  authorizedUserId,
  onRequestDetailsPreview,
  resetCanvas,
  onViewLookBoardsModal,
  showMagnifyIcon,
  showOverlay,
  selectCTLImage,
}) => {
  const { pathname } = useLocation();
  const history = useHistory();

  const matchesMediaQuery = useMediaQuery(maxWidthMd);

  const containerRef = useRef(null);
  const scrollBarRef = useRef(null);
  const [initialScrollerHeight, setInitialScrollerHeight] = useState(0);
  const [customScrollerHeight, setCustomScrollerHeight] = useState(0);
  const [currentUser, setCurrentUser] = useState(null);
  const [imageList, setImageList] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const [currentSearchParams, setCurrentSearchParams] = useState(searchParams);
  const [moreOptionsAnchorEl, setMoreOptionsAnchorEl] = useState(null);
  const [shareAnchorEl, setShareAnchorEl] = useState(null);
  const [changeImageModalOpen, setChangeImageModalOpen] = useState(false);
  const [imgForChange, setImgForChange] = useState(null);

  const selectedImage = useSelector(
    (state) => state.inspirationImage.library[inspirationImageId]
  );

  const {
    id,
    userId,
    title,
    isLiked,
    url,
    type,
    styles: styleIds,
    colors: colorIds,
    roomTypeId,
    itemClassId,
    media: { userId: mediaUserId, hash },
    shareUrl,
    subStyles,
    image_source_name: imageSource,
    source_url: sourceUrl,
    slug,
  } = selectedImage;

  // const [createCancelTokenLoadFirst] = useCancelToken();
  const [createCancelTokenForImages, isCancelled] = useCancelToken();
  const [createCancelTokenForUser] = useCancelToken();

  const [followed, handleFollowChange, confirmModalOpen] = useFollowings({
    followUserId: currentUser?.id,
    followUserName: `${currentUser?.firstName} ${currentUser?.lastName}`,
  });

  const handleUpdateContainerHeight = useCallback(() => {
    setCustomScrollerHeight(
      Math.round(document.documentElement.clientHeight + 50)
    );
  }, [setCustomScrollerHeight]);

  useCustomEventListener(SET_INITIAL_SCROLLER_HEIGHT, () => {
    setCustomScrollerHeight(initialScrollerHeight);
  });

  useCustomEventListener(UPDATE_SCROLLER_HEIGHT, () => {
    handleUpdateContainerHeight();
  });

  useEffect(() => {
    const refHeight = containerRef.current.clientHeight;
    setInitialScrollerHeight(Math.round(refHeight));

    handleUpdateContainerHeight();

    (async () => {
      try {
        const cancelToken = createCancelTokenForUser();
        const someUser = await currentUserService.getUserById(userId, {
          cancelToken,
        });
        setCurrentUser(someUser);
      } catch (e) {
        if (!isCancelled) {
          errorToastr('Error', e.generalError);
        }
      }
    })();
    // eslint-disable-next-line
  }, [createCancelTokenForUser, isCancelled]);

  useEffect(handleUpdateContainerHeight, [
    handleUpdateContainerHeight,
    imageList,
  ]);

  const handleToggleLike = useCallback(async () => {
    if (!authenticated) {
      history.push(
        `${pathname}?${routesByName.auth.key}=${routesByName.auth.signIn}`
      );
      return;
    }
    try {
      await inspirationImageService.toggleLike(id, Number(!isLiked));
      updateLikeStatus(id, Number(!isLiked));
    } catch (e) {
      errorToastr('Error', e.generalError);
    }
  }, [authenticated, history, pathname, id, isLiked, updateLikeStatus]);

  const loadFirst = useCallback(async () => {
    setLoading(true);
    try {
      const cancelToken = createCancelTokenForImages();
      const preparedSubStyles = {};
      subStyles.forEach((subStyle) => {
        preparedSubStyles[subStyle] = 1;
      });
      const filterParams = {
        styles: styleIds,
        colors: colorIds,
        ...(roomTypeId ? { roomTypes: [roomTypeId] } : {}),
        ...(itemClassId ? { itemClasses: [itemClassId] } : {}),
        subStyles: preparedSubStyles,
      };

      const preparedSearchParams =
        currentSearchParams.source === imageSourceKeys.requests
          ? { ...currentSearchParams, source: imageSourceKeys.all }
          : currentSearchParams;

      const [library, result] = await inspirationImageService.getSimilarImages(
        {
          ...preparedSearchParams,
          ...filterParams,
          onlyApproved: true,
          sort: undefined,
        },
        {
          cancelToken,
        }
      );
      updateIILibrary(library);
      setImageList(result.filter(({ id: currId }) => currId !== id));
      setCurrentSearchParams((prevState) => ({
        ...prevState,
        offset: prevState.offset + result.length,
        source:
          prevState.source === imageSourceKeys.requests
            ? imageSourceKeys.all
            : prevState.source,
      }));
      setHasMore(result.length === limit);
      setLoading(false);
    } catch (e) {
      if (!isCancelled(e)) {
        errorToastr('Error', e.generalError);
      }
    }
  }, [
    createCancelTokenForImages,
    styleIds,
    subStyles,
    colorIds,
    roomTypeId,
    itemClassId,
    currentSearchParams,
    updateIILibrary,
    id,
    isCancelled,
  ]);

  useEffect(() => {
    setImageList([]);
    const optionalParams =
      type === imageTypeKeys.room
        ? { roomTypes: [roomTypeId] }
        : { items: itemClassId ? [itemClassId] : [] }; // This ternary only for development purposes, since seed II with type 'single' may not have itemClassId
    setCurrentSearchParams({
      ...searchParams,
      styles: styleIds,
      colors: colorIds,
      ...optionalParams,
      offset: 0,
      source:
        searchParams.source === imageSourceKeys.requests
          ? imageSourceKeys.all
          : searchParams.source,
    });
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    (async () => {
      if (currentSearchParams.offset === 0) {
        await loadFirst();
      }
    })();
    // eslint-disable-next-line
  }, [currentSearchParams.offset]);

  const imgUrl = useMemo(
    () => buildResourceUrl(inspirationImageUrl.medium, mediaUserId, hash),
    [inspirationImageUrl, mediaUserId, hash]
  );

  const handleOpenPreview = useCallback(() => {
    onOpenPreview(imgUrl);
  }, [onOpenPreview, imgUrl]);

  const avatarUrl = useMemo(() => {
    if (currentUser && currentUser.avatar) {
      const { userId: currentUserId, hash: avatarHash } = currentUser.avatar;
      return buildResourceUrl(userAvatarThumbUrl, currentUserId, avatarHash);
    }
    return null;
  }, [currentUser, userAvatarThumbUrl]);

  const loadMore = useCallback(async () => {
    setLoading(true);

    if (currentSearchParams.offset === 0) {
      return;
    }
    const preparedSearchParams =
      currentSearchParams.source === imageSourceKeys.requests
        ? { ...currentSearchParams, source: imageSourceKeys.all }
        : currentSearchParams;

    const preparedSubStyles = {};
    subStyles.forEach((subStyle) => {
      preparedSubStyles[subStyle] = 1;
    });
    const filterParams = {
      styles: styleIds,
      colors: colorIds,
      ...(roomTypeId ? { roomTypes: [roomTypeId] } : {}),
      ...(itemClassId ? { itemClasses: [itemClassId] } : {}),
      subStyles: preparedSubStyles,
    };

    try {
      const cancelToken = createCancelTokenForImages();
      const [library, result] = await inspirationImageService.getSimilarImages(
        {
          ...preparedSearchParams,
          ...filterParams,
          onlyApproved: true,
          sort: undefined,
        },
        {
          cancelToken,
        }
      );

      updateIILibrary(library);
      setImageList((prevState) => {
        return [
          ...prevState,
          ...result.filter(({ id: currId }) => currId !== id),
        ];
      });
      setCurrentSearchParams((prevState) => ({
        ...prevState,
        offset: prevState.offset + result.length,
        source:
          prevState.source === imageSourceKeys.requests
            ? imageSourceKeys.all
            : prevState.source,
      }));

      const updatedListLength = imageList.length + result.length;
      if (!authenticated && updatedListLength >= unauthorizedLimit) {
        setHasMore(false);
      } else {
        setHasMore(result.length === limit);
      }
      setLoading(false);
    } catch (e) {
      if (!isCancelled(e)) {
        errorToastr('Error', e.generalError);
      }
    }
  }, [
    currentSearchParams,
    subStyles,
    styleIds,
    colorIds,
    roomTypeId,
    itemClassId,
    createCancelTokenForImages,
    updateIILibrary,
    imageList.length,
    authenticated,
    id,
    isCancelled,
  ]);

  const handleSelectImage = useCallback(
    (imgId, imgSlug) => {
      onSelectImage(imgId, imgSlug);
      scrollBarRef.current.scrollToTop();
    },
    [onSelectImage]
  );

  const handleOpenConfirmChangeModal = useCallback((img, imgSlug) => {
    setImgForChange({ img, slug: imgSlug });
    setChangeImageModalOpen(true);
  }, []);

  const handleCloseChangeImageModal = useCallback(
    (isConfirmed) => {
      if (isConfirmed && imgForChange) {
        handleSelectImage(imgForChange.img, imgForChange.slug);
        resetCanvas();
      }
      setChangeImageModalOpen(false);
    },
    [imgForChange, handleSelectImage, resetCanvas]
  );

  const handleOnClaimImage = useCallback(() => {
    toggleClaimImageModal(true);
    setClaimImageId(inspirationImageId);
  }, [inspirationImageId, toggleClaimImageModal, setClaimImageId]);

  const handleMoreOptionsPopupOpen = useCallback(({ currentTarget }) => {
    setMoreOptionsAnchorEl(currentTarget);
  }, []);

  const handleMoreOptionsPopupClose = useCallback(() => {
    setMoreOptionsAnchorEl(null);
  }, []);

  const handleOpenTermsModal = useCallback(() => {
    toggleTermsModal(true);
    setMoreOptionsAnchorEl(null);
  }, [toggleTermsModal]);

  const handleCloseSharePopover = useCallback(() => {
    setShareAnchorEl(null);
  }, []);

  const handleClickLookBoardsView = useCallback(() => {
    onViewLookBoardsModal(id);
  }, [id, onViewLookBoardsModal]);

  const handleOpenImageDetailsPage = useCallback(() => {
    history.push(routesByName.inspirationImage.details(id, slug));
  }, [history, id, slug]);

  const showButton = useMemo(
    () =>
      selectedImage.approval === approvalStatusKeys.approved &&
      selectedImage.publish === publishStatusKeys.publishedPlus,
    [selectedImage]
  );

  const handleMoveToCTL = useCallback(() => {
    if (id !== selectedImage) {
      resetCanvas();
    }
    selectCTLImage(id);
    history.push(routesByName.curateTheLook.canvasBySlug(slug));
  }, [id, selectedImage, selectCTLImage, history, slug, resetCanvas]);

  return (
    <div className={clsx('flex-fill d-flex flex-column')}>
      {matchesMediaQuery ? (
        <div className="mt-2 mb-3 d-flex align-items-center" onClick={onBack}>
          <SpriteIcon
            name="left-arrow"
            size="sm"
            className="mr-1 primary-color"
          />
          <span>Back</span>
        </div>
      ) : (
        <div
          className="d-flex align-items-center cursor-pointer mb-3"
          onClick={onBack}
        >
          <Button variant="outlined" className={`${classes.goBackBtn} mt-1`}>
            <SpriteIcon
              name="left-arrow"
              size="sm"
              className="primary-color mr-1"
            />
            <span>Back To All Inspiration Images</span>
          </Button>
        </div>
      )}

      <div className="flex-fill" ref={containerRef}>
        <CustomScrollBar
          scrollBarRef={scrollBarRef}
          autoHeightMin={matchesMediaQuery ? '' : customScrollerHeight}
          // autoHeightMax={matchesMediaQuery ? '' : customScrollerHeight}
          renderView={(props) => (
            <div
              {...props}
              className={clsx({
                'pr-2': !matchesMediaQuery,
              })}
            />
          )}
        >
          {!matchesMediaQuery && sourceUrl && (
            <div
              className={clsx(
                classes.selectedImageLink,
                'text-ellipsis',
                'mb-3'
              )}
            >
              <ImageLink url={sourceUrl} color="grey" />
            </div>
          )}
          <div
            className={clsx(classes.selectedImage, {
              'mb-1': !matchesMediaQuery,
            })}
          >
            {showOverlay && (
              <div className={classes.overlay}>
                <p onClick={handleOpenImageDetailsPage}>View Image Details</p>

                {showButton && (
                  <Button
                    size="sm"
                    inline
                    className="mt-3 mr-1"
                    onClick={handleMoveToCTL}
                    style={{
                      background: 'rgba(255, 255, 255, 0.3)',
                      backdropFilter: 'blur(2px)',
                      borderRadius: '200px',
                      padding: '12px 16px',
                      border: 'none',
                      fontFamily: 'Walkway UltraBold',
                      fontWeight: '400',
                      fontSize: '12px',
                      lineHeight: '16px',
                      textTransform: 'uppercase',
                    }}
                  >
                    <SpriteIcon
                      name="update-arrows"
                      size="sm"
                      className="mr-1"
                    />
                    <span>
                      Curate <br /> this Look
                    </span>
                  </Button>
                )}
              </div>
            )}
            {!matchesMediaQuery && (
              <IconButton
                className={clsx(
                  classes.selectedImageZoom,
                  showMagnifyIcon && classes.alwaysShow
                )}
                color="secondary"
                size="sm"
                onClick={handleOpenPreview}
              >
                <SpriteIcon name="loupe" size="sm" />
              </IconButton>
            )}
            {!matchesMediaQuery && onViewLookBoardsModal && (
              <Button
                className={`${classes.lookBoardsModalBtn} chrome-picker`}
                size="xs"
                color="secondary"
                onClick={handleClickLookBoardsView}
                inline
              >
                <SpriteIcon name="living-room" size="xs" className="mr-1" />
                <span>View Look Boards</span>
              </Button>
            )}

            {!matchesMediaQuery && (
              <>
                <img alt="" draggable="false" src={imgUrl} />
                <>
                  <div className={classes.selectedImageInfo}>
                    <span
                      className={clsx(
                        classes.selectedImageInfoTitle,
                        'text-ellipsis'
                      )}
                    >
                      {title}
                    </span>
                    <div className={classes.selectedImageInfoSubTitle}>
                      {imageSource}
                    </div>
                  </div>
                </>
              </>
            )}
          </div>

          {matchesMediaQuery && (
            <>
              <div className="d-flex align-items-center justify-content-end mt-2">
                {url && (
                  <div className="text-ellipsis">
                    <ImageLink url={sourceUrl} />
                  </div>
                )}
                <IconButton
                  variant="inverted-grey"
                  size="sm"
                  className="mr-1"
                  onClick={handleToggleLike}
                >
                  <SpriteIcon
                    name={isLiked ? 'like-filled' : 'like'}
                    size="sm"
                  />
                </IconButton>
                <IconButton
                  variant="inverted-grey"
                  size="sm"
                  onClick={handleMoreOptionsPopupOpen}
                >
                  <SpriteIcon name="more-vertical" size="sm" />
                </IconButton>
              </div>
            </>
          )}

          {!matchesMediaQuery && (
            <>
              <div className="d-flex align-items-center justify-content-end mb-3">
                {requestId && (
                  <Button
                    className={classes.requestBtn}
                    inline
                    size="sm"
                    onClick={onRequestDetailsPreview}
                  >
                    View Request
                  </Button>
                )}
                <IconButton
                  variant="inverted-white"
                  size="sm"
                  className="mr-1"
                  onClick={handleToggleLike}
                >
                  <SpriteIcon
                    name={isLiked ? 'like-filled' : 'like'}
                    size="sm"
                  />
                </IconButton>
                <IconButton
                  variant="inverted-white"
                  size="sm"
                  onClick={handleMoreOptionsPopupOpen}
                >
                  <SpriteIcon name="more-vertical" size="sm" />
                </IconButton>
              </div>
            </>
          )}

          {!matchesMediaQuery && (
            <>
              <div className={`${classes.bordered} mb-3`}>
                {currentUser && (
                  <div className="d-flex align-items-center">
                    <div className="flex-fill d-flex align-items-center text-ellipsis mr-2">
                      <BasicAvatar
                        firstName={currentUser.firstName}
                        lastName={currentUser.lastName}
                        displayName={currentUser.displayName}
                        src={avatarUrl}
                      />
                      <p className="text-sm ml-1 text-ellipsis font-semi-bold">
                        {currentUser.displayName ??
                          `${currentUser.firstName} ${currentUser.lastName}`}
                      </p>
                    </div>
                    {currentUser?.id !== authorizedUserId && (
                      <Button
                        inline
                        color="secondary"
                        variant={followed ? 'contained' : 'outlined'}
                        size="sm"
                        onClick={handleFollowChange}
                      >
                        {followed ? 'Following' : 'Follow'}
                      </Button>
                    )}
                  </div>
                )}
              </div>
              {!!imageList.length && (
                <p className="mb-3 text-lg text-capitalize font-semi-bold">
                  Similar images
                </p>
              )}
              <CustomInfiniteScroll
                parentHeight={customScrollerHeight}
                isLoading={loading}
                hasMore={hasMore}
                loadMore={loadMore}
                initialLoad={false}
                useWindow={false}
              >
                {imageList.map(
                  ({
                    id: similarImageId,
                    media: {
                      userId: similarImageUserId,
                      hash: similarImageHash,
                    },
                    title: similarImageTitle,
                    image_source_name: imageSourceName,
                    source_url: similarSourceUrl,
                    publish: publishStatus,
                    slug: imageSlug,
                  }) => (
                    <div key={similarImageId} className="mb-2">
                      <SimilarImageItem
                        id={similarImageId}
                        userId={similarImageUserId}
                        hash={similarImageHash}
                        onSelectImage={handleOpenConfirmChangeModal}
                        title={similarImageTitle}
                        imageSource={imageSourceName}
                        sourceUrl={similarSourceUrl}
                        publish={publishStatus}
                        slug={imageSlug}
                      />
                    </div>
                  )
                )}
              </CustomInfiniteScroll>
            </>
          )}
        </CustomScrollBar>
      </div>
      <MoreOptionsPopover
        open={Boolean(moreOptionsAnchorEl)}
        onClose={handleMoreOptionsPopupClose}
        anchorEl={moreOptionsAnchorEl}
        onClaim={handleOnClaimImage}
      >
        <Button
          onClick={handleOpenTermsModal}
          className={`${moreOptionsClasses.btn} mr-1`}
          style={{ minWidth: 110 }}
          size="custom"
          color="default"
        >
          <span>Copyright Terms</span>
        </Button>
      </MoreOptionsPopover>
      <SharePopover
        anchorEl={shareAnchorEl}
        open={Boolean(shareAnchorEl)}
        onClose={handleCloseSharePopover}
        shareUrl={shareUrl}
        directImgUrl={imgUrl}
      />
      <ConfirmModal
        open={changeImageModalOpen}
        onClose={handleCloseChangeImageModal}
        title="Are you sure you want to change the current Inspiration image to the new one?"
      />
      <ConfirmModal
        onClose={handleFollowChange}
        open={confirmModalOpen}
        title="Are you sure you want to unfollow user?"
      />
    </div>
  );
};

SelectedImageBlock.propTypes = {
  inspirationImageId: PropTypes.number.isRequired,
  requestId: PropTypes.number,
  searchParams: PropTypes.shape({ source: PropTypes.string }).isRequired,
  onBack: PropTypes.func.isRequired,
  onOpenPreview: PropTypes.func.isRequired,
  onSelectImage: PropTypes.func.isRequired,
  userAvatarThumbUrl: PropTypes.string.isRequired,
  inspirationImageUrl: PropTypes.shape({
    medium: PropTypes.string.isRequired,
  }).isRequired,
  styles: PropTypes.arrayOf(PropTypes.string).isRequired,
  imgType: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  colors: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  toggleUnderConstructionModal: PropTypes.func.isRequired,
  updateLikeStatus: PropTypes.func.isRequired,
  toggleClaimImageModal: PropTypes.func.isRequired,
  setClaimImageId: PropTypes.func.isRequired,
  toggleTermsModal: PropTypes.func.isRequired,
  updateIILibrary: PropTypes.func.isRequired,
  requestInfo: PropTypes.shape({
    title: PropTypes.string.isRequired,
    priorityItems: PropTypes.arrayOf(PropTypes.number).isRequired,
  }),
  authenticated: PropTypes.bool.isRequired,
  authorizedUserId: PropTypes.number,
  onRequestDetailsPreview: PropTypes.func,
  resetCanvas: PropTypes.func.isRequired,
  selectCTLImage: PropTypes.func.isRequired,
  onViewLookBoardsModal: PropTypes.func,
  showMagnifyIcon: PropTypes.bool,
  showOverlay: PropTypes.bool,
};

SelectedImageBlock.defaultProps = {
  requestId: null,
  requestInfo: null,
  authorizedUserId: null,
  onRequestDetailsPreview: null,
  onViewLookBoardsModal: null,
  showMagnifyIcon: false,
  showOverlay: false,
};

const mapStateToProps = (
  {
    app: { config, enums },
    auth: { user },
    inspirationImage: { library },
    curateTheLook: {
      requestInfo,
      lookBoardData: { requestId },
    },
  },
  { inspirationImageId }
) => ({
  authorizedUserId: user?.id ?? null,
  authenticated: Boolean(user),
  userAvatarThumbUrl: getUserAvatarThumbUrl(config),
  inspirationImageUrl: getInspirationImgUrl(config),
  styles: getStyleNames(library[inspirationImageId]?.styles ?? [], enums),
  imgType: getImageType(library[inspirationImageId] ?? {}, enums),
  colors: getColors(library[inspirationImageId]?.colors ?? [], enums),
  requestId,
  requestInfo,
});

const mapDispatchToProps = {
  toggleUnderConstructionModal: toggleUnderConstructionModalAction,
  updateLikeStatus: updateIIStatusLikeAction,
  toggleClaimImageModal: toggleClaimImageModalAction,
  setClaimImageId: setClaimImageIdAction,
  toggleTermsModal: toggleTermsModalAction,
  updateIILibrary: updateIILibraryAction,
  resetCanvas: resetCanvasAction,
  selectCTLImage: selectCTLImageAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectedImageBlock);
