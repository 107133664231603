import React, { useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { getInspirationImgUrl } from '../../app/store/selectors';
import buildResourceUrl from '../../../utils/buildResourceUrl';

const ImageMeta = ({ image }) => {
  const inspirationImageUrl = getInspirationImgUrl(
    useSelector((state) => state.app.config)
  );
  const imgUrl = useMemo(() => {
    if (!image) {
      return null;
    }

    const {
      media: { userId, hash },
    } = image;

    return buildResourceUrl(inspirationImageUrl.medium, userId, hash);
  }, [image, inspirationImageUrl]);

  const rout = useMemo(
    () =>
      `${window.location.origin}/inspiration-image/${image.id}/${image.slug}`,
    [image.id, image.slug]
  );

  return (
    <Helmet>
      <link rel="canonical" href={rout} />
      <meta property="og:type" content="image" />
      <meta property="og:title" content={image.meta_title} />
      <meta name="title" content={image.meta_title || image.title} />
      <meta property="og:description" content={image.meta_description} />
      <meta
        name="description"
        content={image.meta_description || image.title}
      />
      <meta property="og:image" content={imgUrl} />
      <meta property="og:url" content={window.location.href} />

      <script type="application/ld+json">
        {`
          {
            "@context": "https://schema.org/",
            "@type": "ImageObject",
            "name": "${image.meta_title}",
            "description": "${image.meta_description}",
            "contentUrl": "${imgUrl}",
            "url": "${window.location.href}",
            "thumbnailUrl": "${imgUrl}",
            "uploadDate": "${image.uploadDate}"
          }
        `}
      </script>
    </Helmet>
  );
};
ImageMeta.propTypes = {
  image: PropTypes.shape({
    meta_title: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    meta_description: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    thumbnailUrl: PropTypes.string,
    uploadDate: PropTypes.string,
    media: PropTypes.shape({
      userId: PropTypes.string,
      hash: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

export default ImageMeta;
