import React, { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { requestTheLookTypes } from 'modules/requestTheLook/constants';
import SpriteIcon from 'components/ui/SpriteIcon';
import RequestType from 'modules/requestTheLook/createRequest/components/RequestType';
import createLookBoardClasses from 'modules/curateTheLook/createLookBoard/CreateLookBoard.module.scss';
import RequestTypeInfoModal from 'modules/requestTheLook/components/RequestTypeInfoModal/RequestTypeInfoModal';
import { imageTypeKeys } from 'constants/inspirationImageSearchParams';
import clsx from 'clsx';
import classes from './CreateRequest.module.scss';
import ForceRegisterModal from '../../../components/modals/ForceRegisterModal';

const RightPanel = () => {
  const requestType = useSelector(
    (state) => state.requestTheLook.requestData.requestType
  );
  const library = useSelector((state) => state.inspirationImage.library);
  const isOwnImage = useSelector((state) => state.inspirationImage.isOwnImage);

  const inspirationImageId = useSelector(
    (state) => state.requestTheLook.requestData.inspirationImageId
  );
  const selectedImage = useMemo(() => library[inspirationImageId], [
    library,
    inspirationImageId,
  ]);

  const [infoModalOpen, setInfoModalOpen] = useState(false);

  const handleCloseInfoModal = useCallback(() => {
    setInfoModalOpen(false);
  }, []);

  const handleOpenInfoModal = useCallback(() => {
    setInfoModalOpen(true);
  }, []);

  return (
    <>
      <div
        className={clsx(createLookBoardClasses.sidePanel, classes.rightPanel)}
      >
        <div className={`${createLookBoardClasses.stepTitle} mb-3`}>
          {requestType && (
            <SpriteIcon
              name="checked"
              size="sm"
              className="mr-1 primary-color"
            />
          )}
          <span>Step 2: Select Request Type</span>
        </div>
        <RequestType
          type={imageTypeKeys.room}
          requestType={imageTypeKeys.room}
          title={requestTheLookTypes[imageTypeKeys.room].title}
          imgUrl={requestTheLookTypes[imageTypeKeys.room].imgUrl}
          description={requestTheLookTypes[imageTypeKeys.room].description}
          selectedImage={selectedImage}
          onOpenModal={handleOpenInfoModal}
        />
        {isOwnImage && (
          <RequestType
              type={imageTypeKeys.makeOver}
              requestType={imageTypeKeys.makeOver}
              title={requestTheLookTypes[imageTypeKeys.makeOver].title}
              imgUrl={requestTheLookTypes[imageTypeKeys.makeOver].imgUrl}
              description={
                  requestTheLookTypes[imageTypeKeys.makeOver].description
              }
              selectedImage={selectedImage}
              onOpenModal={handleOpenInfoModal}
          />
        )}
      </div>
      <RequestTypeInfoModal
        type={requestType}
        open={infoModalOpen}
        onClose={handleCloseInfoModal}
      />
      <ForceRegisterModal type="RTL" />
    </>
  );
};

export default RightPanel;
