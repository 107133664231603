import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { filterModalArrayOf } from '../../../../../modules/requestTheLook/requestDetails/components/FilterModal/propTypes';
import classes from '../../FiltersModal.module.scss';

const ItemsFilters = ({ options, currentValue, onChange, maxHeight }) => {
  return (
    <div className={clsx('mb-3', classes.itemsList)} style={{ maxHeight }}>
      {options.map(({ id: value, name: label }) => (
        <div key={label}>
          <FormControlLabel
            className={clsx('mx-0 align-items-center mb-1', {})}
            label={label}
            shouldDisableCheckbox={() => false}
            control={
              <h3 className="d-flex align-items-center">
                <Checkbox
                  id={String(value)}
                  checked={currentValue?.includes(value)}
                  onChange={onChange}
                  size="medium"
                  className={classes.checkbox}
                />
              </h3>
            }
          />
        </div>
      ))}
    </div>
  );
};

ItemsFilters.propTypes = {
  options: filterModalArrayOf.isRequired,
  currentValue: PropTypes.arrayOf(PropTypes.number).isRequired,
  onChange: PropTypes.func.isRequired,
  maxHeight: PropTypes.number.isRequired,
};

export default ItemsFilters;
